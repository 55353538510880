var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _vm._m(0),
    _c("div", { staticClass: "text" }, [
      _c("div", { staticClass: "left" }, [
        _c("p", [_vm._v("客单价（元）")]),
        _c("p", { staticStyle: { "font-family": "Digital-7Mono" } }, [
          _vm._v(_vm._s(_vm._f("moneyHandling")(_vm.customerUnitPrice))),
        ]),
      ]),
      _c("div", { staticClass: "right" }, [
        _c("p", [_vm._v("平均泊位收入（元）")]),
        _c("p", { staticStyle: { "font-family": "Digital-7Mono" } }, [
          _vm._v(_vm._s(_vm._f("moneyHandling")(_vm.avgBerthIncome))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("div", { staticClass: "guestUnitPrice" }),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "averageBerthRevenue" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }