var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { "padding-right": "15px" }, attrs: { span: 12 } },
            [_c("turnover", { attrs: { dataList: _vm.dataList } })],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "padding-right": "15px" }, attrs: { span: 12 } },
            [_c("utilization", { attrs: { dataList: _vm.dataList } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }