var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "title",
      style: {
        background:
          "url(" +
          (_vm.title == "周转率"
            ? _vm.bg2
            : _vm.title == "利用率"
            ? _vm.bg2
            : _vm.bg1) +
          ") no-repeat center bottom",
        "background-size": "100% 100%",
      },
    },
    [
      _c("el-row", { attrs: { span: 24 } }, [
        _c("div", { staticStyle: { float: "left" } }, [
          _c("span", { staticClass: "title_span" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }