var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chartsContent-wrap" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _vm._v(" 实时泊位占比 "),
        _c("tips", {
          attrs: {
            tip: "泊位占比：统计范围内已用泊位与总泊位数量比值。表征区域或单个停车场泊位供给是否充足，指标越大，表明当前可供使用泊位越少，支撑区域停车发展、停车场资源服务（调度、引流等）。",
          },
        }),
      ],
      1
    ),
    _c("div", {
      staticClass: "leftThreeChart",
      attrs: { id: "leftThreeChart" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }