var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "title" },
    [
      _c("el-row", { attrs: { span: 24 } }, [
        _c(
          "div",
          { staticStyle: { float: "left" } },
          [
            _c("span", { staticClass: "title_span" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _c("tips", { attrs: { tip: _vm.tip } }),
          ],
          1
        ),
        _c("div", { staticStyle: { float: "right" } }, [
          _c(
            "ul",
            { staticClass: "selcetTimeRange" },
            _vm._l(
              _vm.deleteToday ? _vm.newTypeList : _vm.typeList,
              function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: _vm.activeLI == item.type ? "activeli" : "normalli",
                    on: {
                      click: function ($event) {
                        return _vm.selcetTimeRange(item.type)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }
            ),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }