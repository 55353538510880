var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg" }, [
    _c("div", { staticClass: "leftChartsContent" }, [
      _c("div", { staticClass: "chartsContent" }, [
        _c("div", { staticClass: "flexItem" }, [_c("info-child")], 1),
        _c("div", { staticClass: "flexItem" }, [_c("berth-forecast")], 1),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }